<template>
	<div class="topbar-item">
		<div class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2" id="kt_quick_user_toggle">
			<span class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
				Hi,
			</span>
			<span class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
				{{ currentUser.name }}
			</span>
			<span class="symbol symbol-35 symbol-light-success">
				<img v-if="currentUserAvatarThumb" alt="Pic" :src="currentUserAvatarThumb" />
				<span v-if="!currentUserAvatarThumb" class="symbol-label font-size-h5 font-weight-bold">
					{{ initial }}
				</span>
			</span>
		</div>

		<div id="kt_quick_user" ref="kt_quick_user" class="offcanvas offcanvas-right p-10">
			<!--begin::Header-->
			<div class="offcanvas-header d-flex align-items-center justify-content-between pb-5">
				<h3 class="font-weight-bold m-0">
					User Profile
				</h3>
				<a href="#" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_user_close">
					<i class="ki ki-close icon-xs text-muted"></i>
				</a>
			</div>
			<!--end::Header-->

			<!--begin::Content-->
			<perfect-scrollbar class="offcanvas-content pr-5 mr-n5 scroll" style="max-height: 90vh; position: relative;">
				<!--begin::Header-->
				<div class="d-flex align-items-center mt-5">
					<div class="symbol symbol-100 mr-5">
						<img class="symbol-label" :src="currentUserAvatar ? currentUserAvatar : '/media/svg/avatars/default-avatar.png'" alt="avatar" />
						<i class="symbol-badge bg-success"></i>
					</div>
					<div class="d-flex flex-column">
						<span class="font-weight-bold font-size-h5 text-dark-75">
							{{ currentUser.name }}
						</span>
						<div class="text-muted mt-1 text-uppercase">{{ currentUser.role }}</div>
						<div class="navi mt-2 mb-2">
							<span href="#" class="navi-item">
								<span class="p-0 pb-2">
									<span class="navi-icon mr-1">
										<span class="svg-icon svg-icon-lg svg-icon-primary">
											<!--begin::Svg Icon-->
											<inline-svg src="/media/svg/icons/Communication/Mail-notification.svg" />
											<!--end::Svg Icon-->
										</span>
									</span>
									<span class="text-muted">
										{{ currentUser.email }}
									</span>
								</span>
							</span>
						</div>
						<button class="btn btn-light-primary btn-bold" @click="logOut">
							Sign out
						</button>
					</div>
				</div>
				<!--end::Header-->
				<div class="separator separator-dashed mt-8 mb-5"></div>
				<!--begin::Nav-->
				<div class="navi navi-spacer-x-0 p-0">
					<!--begin::Item-->
					<router-link :to="currentUser.role == 'video' ? '/app/profile' : '/profile'" @click.native="closeOffcanvas" href="#" class="navi-item">
						<div class="navi-link">
							<div class="symbol symbol-40 bg-light mr-3">
								<div class="symbol-label">
									<span class="svg-icon svg-icon-md svg-icon-success">
										<!--begin::Svg Icon-->
										<inline-svg src="/media/svg/icons/General/Notification2.svg" />
										<!--end::Svg Icon-->
									</span>
								</div>
							</div>
							<div class="navi-text">
								<div class="font-weight-bold">My Profile</div>
								<div class="text-muted">
									Account settings and more
									<span class="label label-light-warning label-inline font-weight-bold">
										update
									</span>
								</div>
							</div>
						</div>
					</router-link>
					<!--end:Item-->
				</div>
				<!--end::Nav-->
				<div class="separator separator-dashed my-7"></div>
			</perfect-scrollbar>
			<!--end::Content-->
		</div>
	</div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
	overflow: hidden;
}
</style>

<script>
import { getToken } from '../../../../../src/authUtils.js'
import { mapGetters } from 'vuex';
import { CLEAR_AUTH_COOKIES } from '@/core/services/store/auth.module';
import { GET_USER_PROFILE } from '@/core/services/store/profile.module';
import KTLayoutQuickUser from '@/assets/js/layout/extended/quick-user.js';
import KTOffcanvas from '@/assets/js/components/offcanvas.js';

export default {
	name: 'KTQuickUser',
	data() {
		return {
			token: ''
		};
	},
	mounted() {
		// Init Quick User Panel
		KTLayoutQuickUser.init(this.$refs['kt_quick_user']);
	},
	methods: {
		logOut() {
			this.token = getToken();
			this.$store.dispatch(CLEAR_AUTH_COOKIES, this.token);
		},
		closeOffcanvas() {
			new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
		},
	},
	computed: {
		...mapGetters(['currentUser', 'currentUserAvatar', 'currentUserAvatarThumb']),
		initial() {
			if (this.currentUser.name) {
				return this.currentUser.name.toUpperCase().charAt(0);
			} else {
				this.$store.dispatch(GET_USER_PROFILE);
				return '';
			}
		},
		picture() {
			return process.env.BASE_URL + 'media/users/300_21.jpg';
		},
	},
};
</script>
