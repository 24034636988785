<template>
	<!-- begin:: Header -->
	<div id="kt_header" ref="kt_header" class="header" v-bind:class="[{ 'viewing-as-header': viewAs.AccountID }, headerClasses]">
		<div class="row container-fluid d-flex align-items-center justify-content-between ml-1">
			<div>
				<router-link :to="{ path: '/' }">
					<img alt="Logo" :src="layoutConfig('self.logo.default')" class="logo-default max-h-50px" />
				</router-link>
			</div>
			<div>
				<KTQuickUser></KTQuickUser>
			</div>
		</div>
	</div>
	<!-- end:: Header -->
</template>

<script>
import { mapGetters } from 'vuex';
import KTQuickUser from '@/view/layout/extras/offcanvas/QuickUser.vue';
import KTLayoutHeader from '@/assets/js/layout/base/header.js';
import KTLayoutHeaderMenu from '@/assets/js/layout/base/header-menu.js';

export default {
	name: 'KTHeader',
	components: {
		KTQuickUser,
		ViewAsHeader: () => import('@/view/layout/header/ViewAsHeader.vue'),
	},
	mounted() {
		// Init Desktop & Mobile Headers
		KTLayoutHeader.init('kt_header', 'kt_header_mobile');

		// Init Header Menu
		KTLayoutHeaderMenu.init(this.$refs['kt_header_menu'], this.$refs['kt_header_menu_wrapper']);
	},

	computed: {
		...mapGetters(['layoutConfig', 'getClasses', 'viewAs']),

		/**
		 * Check if the header menu is enabled
		 * @returns {boolean}
		 */
		headerMenuEnabled() {
			return !!this.layoutConfig('header.menu.self.display');
		},

		/**
		 * Get extra classes for header based on the options
		 * @returns {null|*}
		 */
		headerClasses() {
			const classes = this.getClasses('header');
			if (typeof classes !== 'undefined') {
				return classes.join(' ');
			}
			return null;
		},

		/**
		 * Get extra classes for header menu based on the options
		 * @returns {null|*}
		 */
		headerMenuClasses() {
			const classes = this.getClasses('header_menu');
			if (typeof classes !== 'undefined') {
				return classes.join(' ');
			}
			return null;
		},
	},
};
</script>

<style scoped>
.viewing-as-header {
	border-top: 2px solid red;
}
</style>
