<template>
	<div id="kt_header_mobile" class="header-mobile align-items-center" v-bind:class="headerClasses">
		<a href="/">
			<img alt="Logo" :src="layoutConfig('self.logo.dark')" class="max-h-50px" />
		</a>
		<div class="d-flex align-items-center">
			<button v-if="asideEnabled" class="btn p-0 burger-icon burger-icon-left" id="kt_aside_mobile_toggle">
				<span></span>
			</button>
			<button class="btn p-0 burger-icon ml-4" @click="toggleTopbar">
				<span></span>
			</button>
			<!-- <button class="btn btn-hover-text-primary p-0 ml-2" @click='toggleUser'>
				<span class="svg-icon svg-icon-xl">
					<inline-svg class="svg-icon" src="/media/svg/icons/General/User.svg" />
				</span>
			</button> -->
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME } from '@/core/services/store/htmlclass.module.js';

// import KTLayoutHeaderTopbar from '@/assets/js/layout/base/header-topbar.js';

export default {
	name: 'KTHeaderMobile',
	components: {},
	mounted() {
		// Init Header Topbar For Mobile Mode
		// KTLayoutHeaderTopbar.init(this.$refs['kt_header_mobile_topbar_toggle']);
	},
	data() {
		return {
			showTopbar: false,
		};
	},
	computed: {
		...mapGetters(['layoutConfig', 'getClasses']),
		headerClasses() {
			const classes = this.getClasses('header_mobile');
			if (typeof classes !== 'undefined') {
				return classes.join(' ');
			}
			return null;
		},
		asideEnabled() {
			return !!this.layoutConfig('aside.self.display');
		},
	},
	methods: {
		toggleTopbar() {
			this.showTopbar
				? this.$store.dispatch(REMOVE_BODY_CLASSNAME, 'topbar-mobile-on')
				: this.$store.dispatch(ADD_BODY_CLASSNAME, 'topbar-mobile-on');
			this.showTopbar = !this.showTopbar;
		},
		toggleUser() {},
	},
};
</script>
